import React, { useState, useEffect } from 'react';
import './Popup.css'; // Import your CSS file for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useData } from '../Context';
import { useModalContext } from '../hooks/useModalState';

import footerlogo1 from '../images/SHOPANDRETAILlogo.png';
import micIcon from '../images/mic.png';
import brandimg from '../images/brandmodalimg.png'; // Correctly import the image
import available from '../images/ClerksAvailable.png';
import { saroAPI, retailerNAME, imageUrl } from './constant';

const Body = ({ onPageChange, setShowModal }) => {
  const [isLoading, setIsLoading] = useState(true); // Start with loading state as true
  const [showNoClerksPopup, setShowNoClerksPopup] = useState(false); // Control to show popup
  const [clerksAvailable, setClerksAvailable] = useState(true); // Control to show main content

  const navigate = useNavigate(); // Define useNavigate here
  const { setData } = useData();
  const { setModalState } = useModalContext();

  const checkClerkAvailability = async (retailerName) => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJmYWl6YSIsImlhdCI6MTcyMzgxODM2Mn0.JCOcOBZPO2-VOYU6IsjvqBKwsieNPWecFhV2IP-NjeA";

    try {
      const response = await fetch(`${saroAPI}/getOnlineClerks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ retailerName }),
      });

      if (response.ok) {
        const responseData = await response.json();
        return responseData.data.length > 0; // Return true if there are online clerks
      } else {
        console.error('API Error:', response.statusText);
        return false;
      }
    } catch (error) {
      console.error('API Crash Error:', error.message);
      return false;
    }
  };

  useEffect(() => {
    setModalState('dynamic');

    // Automatically check for clerk availability when the component mounts
    const checkClerks = async () => {
      const retailerName = retailerNAME;
      const clerksAvailable = await checkClerkAvailability(retailerName);
      setIsLoading(false); // Stop the loading state once the check is complete

      if (!clerksAvailable) {
        setClerksAvailable(false);
        setShowNoClerksPopup(true);
      } else {
        setClerksAvailable(true);
      }
    };

    checkClerks();

  }, []);

  const closePopup = () => {
    // Optionally, you could navigate away or handle the close action as needed
    setShowNoClerksPopup(false);
    setShowModal(false);
  };

  const handleButtonClick = () => {
    if (clerksAvailable) {
      onPageChange("Permission");
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="small-overlay">
          <div className="small-loader"></div>
        </div>
      )}

      {showNoClerksPopup && (
        <div className={window.innerWidth > 760 ? 'centered-container ' : 'centered-container'}>
          <div className="row bodyContainer2ndDiv marginRL">
            <div className="col-12">
              <h3
                className="header4"
                style={{
                  fontSize: window.innerWidth <= 768 ? "16px" : "20px",
                  fontFamily: "'Roboto', sans-serif",
                  marginTop: window.innerWidth <= 768 ? "10px" : "15px",
                  textAlign: window.innerWidth <= 768 ? "center" : "center",
                  lineHeight: window.innerWidth <= 768 ? "1.2" : "inherit",
                  paddingLeft:"12px",
                  paddingRight:"12px",
                }}
              >
                Our clerks are unavailable at the moment  
              </h3>
              <h4
                style={{
                  fontSize: window.innerWidth <= 768 ? "12px" : "14px",
                  fontFamily: "'Roboto', sans-serif",
                  color: '#4F4F4F',
                  textAlign: "center",
                  lineHeight: "2",
                  marginTop: window.innerWidth <= 768 ? "5px" : "-10px",
                  marginBottom: window.innerWidth <= 768 ? '10px' : '8px',
                  maxWidth:"500px",
                  paddingLeft:"12px",
                  paddingRight:"12px",
                  paddingTop:"12px"
                }}
              >
                We appreciate your patience. Feel free to browse our latest products while we find someone to assist you
              </h4>
            </div>
          </div>
          <div>
  <div>
    <img
      src={`${imageUrl}${available}`}
      alt="Clock"
      className="clock-image"
      style={{
        marginTop: window.innerWidth <= 768 ? '10px' : '30px',
        maxWidth: window.innerWidth <= 768 ? '100px' : '150px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
      }}
      id="cimage1"
    />
  </div>
</div>

          <div className="row marginRL">
            <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100px', marginBottom: "-30px" }}>
              <a
                href="https://shoparonline.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',  // Ensures vertical alignment
                  justifyContent: 'center'
                }}
              >
                <span
                  style={{
                    marginRight: '5px',
                    fontSize: '10px',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    lineHeight: '11px' // Matches the line height with the logo height
                  }}
                >
                  Powered by
                </span>
                <img 
        src={footerlogo1} 
          className="footer" 
          id="footer" 
          alt="Saro Logo" 
          style={{
           maxWidth:"100%", // Matches the logo height with the text line height
            height: "auto",
            display: 'block',
            verticalAlign: 'middle' // Aligns the logo vertically in the middle
          }} 
        />
              </a>
            </div>
          </div>


        </div>
      )}


      {!showNoClerksPopup && !isLoading && clerksAvailable && (
        <div className={window.innerWidth > 760 ? 'centered-container ' : 'container-fluid fullheight d-flex flex-column justify-content-around bodyContainer'}>
          <div className="row bodyContainer2ndDiv marginRL">
            <div className="col-12">
              <h3
                className="header3"
                style={{
                  fontSize: window.innerWidth <= 768 ? "20px" : "20px", // 16px for mobile, 20px for desktop
                  fontFamily: "'Roboto', sans-serif",
                  marginTop: window.innerWidth <= 768 ? '20px' : '30px'
                }}
              >
                Watch a Live Demo & Expert Tips
              </h3>
              <h2
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  marginBottom: window.innerWidth <= 768 ? '10px' : '-25px',
                  fontSize: '14px',
                  color: '#4F4F4F',
                  padding: window.innerWidth > 760 ? '0 10px' : '0 20px',
                  fontFamily: "'Roboto', sans-serif",
                }}
              >
                Interested in a live demonstration of our product or getting some tips?
              </h2>
            </div>

          </div>
          <div className="row d-flex w-full marginRL">
          <div className="col-6 d-flex align-items-center justify-content-center imageDiv w-full">
  <img
    src={`${imageUrl}${brandimg}`}
    style={{
      marginTop: window.innerWidth <= 768 ? '20px' : '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    }}
    alt="Image"
    className="img-fluid ms-9"
    id="cimage1"
  />
</div>

            <div className="col-6 d-flex flex-column justify-content-center contentDiv w-full">
            <p style={{
  fontSize: window.innerWidth <= 768 ? "12px" : "12px",
  marginTop: window.innerWidth <= 768 ? '0px' : '50px',
  fontFamily: "'Roboto', sans-serif",
  display: window.innerWidth <= 768 ? 'none' : 'block',
  textAlign: "left",
  maxWidth: "350px" // You can adjust this value based on your design needs
}}>
  Engage with our live expert now. You can see our team, but we only see you when you allow
</p>
  
  <div className="d-flex flex-column align-items-center">
    <button className="bb1" onClick={handleButtonClick}>
      <img src={micIcon} alt='mic' className='micimg img-fluid ms-9' /> Call with Expert
    </button>
    <button className="bb1Outline" style={{ marginTop: window.innerWidth > 768 ? '7px' : '10px' }}
      onClick={() => window.open("https://shoparonline.com/", "_blank")}>
      Contact Us
    </button>
  </div>
</div>

          </div>
          <div className="row marginRL">
            <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100px', marginBottom: window.innerWidth > 768 ? '-30px' : '-40px', }}>
              <a
                href="https://shoparonline.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',  // Ensures vertical alignment
                  justifyContent: 'center'
                }}
              >
                <span
                  style={{
                    marginRight: '5px',
                    fontSize: '10px',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    lineHeight: '11px' // Matches the line height with the logo height
                  }}
                >
                  Powered by
                </span>
                <img 
        src={footerlogo1} 
          className="footer" 
          id="footer" 
          alt="Saro Logo" 
          style={{
           maxWidth:"100%", // Matches the logo height with the text line height
            height: "auto",
            display: 'block',
            verticalAlign: 'middle' // Aligns the logo vertically in the middle
          }} 
        />
              </a>
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default Body;
